'use strict';

angular.module('managerApp').config( ($routeProvider) => {
    $routeProvider.when('/webapps', {
        templateUrl: 'app/webapps/list.html',
        controller: 'WebAppsController',
        authenticate: 'user',
        hasPermissions: ['WEBAPPS']
    }).when('/webapps/partners/id/:id', {
        templateUrl: 'app/webapps/list.html',
        controller: 'WebAppsController',
        authenticate: 'user',
        hasPermissions: ['WEBAPPS:GET']
    }).when('/webapps/partners', {
        templateUrl: 'app/webapps/partners/list.html',
        controller: 'WebAppPartnersController',
        authenticate: 'user',
        hasPermissions: ['WEBAPPS:GET']
    }).when('/webapps/add', {
        templateUrl: 'app/webapps/add.edit.webapp.html',
        controller: 'WebAppsController',
        authenticate: 'user',
        hasPermissions: ['WEBAPPS:POST'],
        reloadOnSearch: false
    }).when('/webapps/:id', {
        templateUrl: 'app/webapps/item.html',
        controller: 'WebAppsController',
        authenticate: 'user',
        hasPermissions: ['WEBAPPS:GET']
    }).when('/webapps/:id/edit', {
        templateUrl: 'app/webapps/add.edit.webapp.html',
        controller: 'WebAppsController',
        authenticate: 'user',
        hasPermissions: ['WEBAPPS:POST']
    });
});
